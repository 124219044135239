.balance-div {
  background: #222936;
  border-radius: 30px;
  padding: 20px 0px;
  margin: 20px 0;
  h2 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 12px;
    margin: 5px 0 0;
    color: rgba(255, 255, 255, 0.4);
  }

  .input-container {
    padding: 5px 7px;
  }
  .custom-white-button {
    padding: 10px 12px;
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
    font-family: "Source Sans 3", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #1f2632;
    flex: 0 0 auto;
    @media (max-width: 992px) {
      margin-left: 15px;
    }
    &:hover {
      background: #86ccff;
      color: #000;
      border: 1px solid #86ccff;
    }
  }
}
.balance-history {
  p {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 15px;
  }
  .balance-listing {
    max-height: 400px;
    overflow: auto;
    li {
      border-bottom: 1px solid #272e3c;
      padding: 15px 0;
      &:last-child {
        border-bottom: 0;
      }
      div.left-col {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .right-col {
        text-align: right;
      }
      p {
        font-size: 14px;
        margin: 0 0 0 0px;
      }
      h3 {
        font-size: 16px;
        margin: 0;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 886px) {
  .balance-div {
    h2 {
      font-size: 22px;
    }
  }
}
