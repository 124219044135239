.p-0 {
  padding: 0px;
}

.trade-body {
  background-color: #131820;
}
.center-line::after {
  content: "";
  background: url("data:image/svg+xml,%3Csvg width='1' height='458' viewBox='0 0 1 458' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.15' width='1' height='458' rx='0.5' fill='url(%23paint0_linear_5512_2841)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_5512_2841' x1='0.5' y1='0' x2='0.5' y2='458' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D9D9D9' stop-opacity='0'/%3E%3Cstop offset='0.479167' stop-color='%23D9D9D9'/%3E%3Cstop offset='1' stop-color='%23D9D9D9' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  position: absolute;
  right: 0;
  display: inline-block;
  width: 1px;
  height: 100%;
  top: 0;
  @media (max-width: 886px) {
    display: none;
  }
}

.overlap-new-group {
  .content-box {
    max-width: 1080px;
    transition: none;
    @media (max-width: 1200px) {
      max-width: 960px;
    }
  }
}
.content-box {
  border-radius: 20px;
  border: 1px solid #f6c171;
  background: #191f29;
  box-shadow: 0px 4px 21px 0px rgba(154, 154, 154, 0.25);
  margin-top: 52px;
  padding: 25px 40px;
  color: #fff;
  max-width: 530px;
  margin: 67px auto;
  transition: 0.5s all ease-in-out;
  position: relative;
  &.active {
    max-width: 1080px;
    transition: 0.5s all ease-in-out;
    @media (max-width: 1200px) {
      max-width: 960px;
    }
  }
  .justify-content-between {
    display: flex;
    justify-content: space-between;
    /* Add this line */
  }

  .box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-radius: 20px 20px 0px 0px;
  }

  h5 {
    margin: 0;
    font-family: "IBM Plex Mono", monospace;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #ffc670;
  }

  .box-header-icon {
    border: 1px solid #fff;
    padding: 12px;
    border-radius: 50%;
    height: 50px;
    width: auto;
    margin-left: auto;
    cursor: pointer;
  }

  .box-header-icon:hover {
    background-color: #131820;
  }
}

.amount-input {
  .border-column {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    .enabled-text {
      color: rgba(228, 186, 79, 1);
      font-size: 16px;
      @media (max-width: 767px) {
        font-size: 14px;
        svg {
          max-width: 22px;
        }
      }
    }
    .subscribe-text {
      font-size: 16px;
      color: #ff5353;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 14px;
        svg {
          max-width: 22px;
        }
      }
    }
  }
}

.floor-card {
  padding: 20px;
  border-radius: 30px;
  background: #222936;
  border: 1px solid #222936;
}

.overlap-group {
  background-image: url(https://generation-sessions.s3.amazonaws.com/2244f7c3e7e6969c626def214d4ac5e8/img/group-1707484846.png);
  background-size: cover;
  padding: 30px 0;
  min-height: calc(100vh - 153px);
}

.Configure-info {
  padding: 15px 18px;
  border-radius: 10px;
  border: 1px dashed #898989;
  background: #131820;
  margin-bottom: 25px;

  .Configure-icon {
    margin-right: 12px;
  }

  p {
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
  }
}

.tabs-section {
  margin-bottom: 20px;
  &.custom-tabs-section {
    border-top: 1px solid rgba(217, 217, 217, 0.1);
    padding: 24px 0 0;
  }
  &.custom-check-tabs {
    padding: 10px 0 0;
    @media (max-width: 640px) {
      position: relative;
    }
    .custom-label {
      font-size: 16px;
      font-weight: 600;
      @media (max-width: 886px) {
        font-size: 14px;
      }
      img {
        max-width: 20px;
      }
      .tool-tip-container {
        .tooltip-container {
          z-index: 9;
          @media (max-width: 640px) {
            position: inherit;
          }
        }
      }
    }
    .custom-checkbox {
      margin-bottom: 0;
      .checkbox-input {
        &:checked {
          & + span.checkbox-icon {
            background: #6d9dff;
            border-color: #6d9dff;
            &:before {
              left: 23px;
            }
          }
        }
      }
      span.checkbox-icon {
        cursor: pointer;
        display: block;
        margin-right: 0;
        pointer-events: all;
        width: 50px;
        height: 30px;
        background: #cccccc;
        border-color: #cccccc;
        border-radius: 50px;
        transition: 300ms linear;
        @media (max-width: 767px) {
          width: 45px;
          height: 25px;
          padding: 0;
          right: 20px;
        }
        @media (max-width: 640px) {
          right: 0;
          margin: 0;
        }
        .checkmark-icon {
          display: none;
        }
        &:before {
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 23px;
          height: 23px;
          background: #ffffff;
          border-radius: 50%;
          transition: left 300ms linear;
          @media (max-width: 767px) {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }
}

.type-value {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  @media (max-width: 767px) {
    gap: 0;
    .select-container {
      max-width: 48%;
      .select-input-style {
        padding: 10px 10px !important;
      }
    }
  }

  &.p-0 {
    .input-container {
      border-radius: 10px;
    }
  }
}

.type-value-1 {
  display: block;
  gap: 15px;
  justify-content: space-between;

  &.p-0 {
    .input-container {
      border-radius: 10px;
      padding: 11px 10px 11px 10px;
    }
  }
}

.select-container {
  width: 100%;
}

.dca-check {
  padding: 0px !important;
  margin: 0px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

@media (max-width: 886px) {
  .content-box {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 10px 0px 25px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .Configure-info p {
    font-size: 16px;
  }

  .overlap-group {
    padding: 30px 20px 80px;
    min-height: calc(100vh - 190px);
  }
}

.popup-outer {
  background: #161a22;
  border: 1px solid rgba(228, 237, 255, 0.15);
  position: absolute;
  border-radius: 30px 30px 7px 7px;
  animation: cssAnimation 0.5s 3s forwards;
  visibility: hidden;
  top: 102px;
  transition: 0.3s all ease-in-out;
  right: -100%;
  max-width: 400px;
  z-index: 9;
  @media (max-width: 767px) {
    max-width: calc(100% - 20px);
    z-index: 9;
    top: 10px;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    animation: cssAnimationMobile 0.5s 3s forwards;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .cross-btn {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    height: 30px;
    z-index: 9;
    width: 30px;
    @media (max-width: 767px) {
      padding: 0;
    }
    svg {
      max-width: 16px;
    }
  }
  .popup-content {
    padding: 20px 20px 20px;
    h2 {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      position: relative;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      span {
        position: relative;
        right: -15px;
        top: 2px;

        @media (max-width: 767px) {
          top: 0;
        }
        &:before {
          content: " ";
          position: absolute;
          z-index: 2;
          right: -15px;
          top: 3px;
          width: 15px;
          height: 15px;
          background-color: #198754;
          border-radius: 50%;
        }
        &:after {
          content: " ";
          position: absolute;
          top: 4px;
          z-index: 1;
          width: 15px;
          background-color: #198754;
          right: -30px;
          height: 15px;
          border-radius: 50%;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
          animation: ripple 1s ease 0s infinite normal;
        }
      }
    }
    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      margin: 0;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    .custom-button {
      padding: 7px 12px;
      border-radius: 6px;
      color: #000;
      background: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      border: none;
      &:hover {
        background-color: #86ccff;
      }
    }
  }
}

@keyframes cssAnimation {
  to {
    visibility: visible;
    right: 20px;
  }
}

@keyframes cssAnimationMobile {
  to {
    visibility: visible;
    right: 0px;
  }
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 9px;
    opcity: 75;
    width: 0;
    height: 0;
  }
  100% {
    left: -18px;
    top: -14px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}
.meter {
  height: 5px;
  position: relative;
  border-radius: 0 0 7px 7px;
  background: #f3efe6;
  overflow: hidden;
}

.meter span {
  display: block;
  height: 100%;
}

.progresss {
  background-color: #3396ff;
  -webkit-animation: progressBar 15s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation: progressBar 15s ease-in-out;
  animation-fill-mode: both;
  animation-delay: 4s;
}

.switch-network-warn {
  text-decoration: underline;
  cursor: pointer;
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.custom-amount-input {
  input {
    font-size: 24px;
    font-weight: 600;
    max-width: 60%;
  }
}

.custom-dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  .custom-dropdown-setting {
    min-width: 400px;
    left: auto !important;
    right: 0 !important;
    border: 0;
    @media (max-width: 886px) {
      min-width: 320px;
      left: 0 !important;
      right: 0 !important;
      max-width: 100%;
      z-index: 99;
      position: fixed !important;
      bottom: 130px !important;
      background: #272b32 !important;
      top: auto !important;
      padding: 0px 10px 20px;
      border: 1px solid #576781;
      border-radius: 20px 20px 0 0;
    }
    .confirm-dialog-card {
      background: #272b32;
      margin: 0;
      @media (max-width: 886px) {
        margin: 0 auto;
      }
    }
  }
}

.amount-input {
  .input-container {
    .balance-text {
      font-size: 14px;
      color: #47556b;
      span {
        font-size: 16px;
        color: #fff;
        display: inline-block;
        margin: 0 8px;
      }
      button {
        color: #4ddcdc;
        background: rgba(255, 255, 255, 0.1);
        padding: 5px 7px;
        border-radius: 4px;
        font-size: 12px;
      }
    }
  }
}
