/* ToggleSwitch.scss */

.toggle-switch {
    display: flex; 
    border-radius: 4px;
    overflow: hidden;
    width: 144px;
    border-radius: 10px;
    background: #262D39;
    padding: 6px 8px;
}

.toggle-option {
    border-radius: 5px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    flex-shrink: 0;
    text-align: center;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
    width: 50%;
}

.toggle-option.selected {
    background: #FFF;
    color: #454545;

}

@media (max-width: 426px) {
    .toggle-option {
        font-size: 12px;
    }
}