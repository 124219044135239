/* DropdownSelect.css */

.dropdown-select {
  position: relative;
  display: inline-block;
  font-family: "Source Sans 3", sans-serif;
  width: 100%;
  .placeholder {
    img {
      display: none;
    }
  }
}

.selected-option .placeholder {
  &.first-selection {
    color: #6d7786;
  }
  background: #131820;
  color: #fff;
  opacity: 1;
}

.selected-option {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  color: #ccc;
  padding: 17px 20px;
  gap: 83px;
  border-radius: 10px;
  border: 1px solid #898989;
  background: #131820;
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.dropdown-icon {
  width: 16px;
  height: 16px;
  right: 13px;
  position: absolute;
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #222936;
  border: 1px solid #898989;
  border-top: none;
  border-radius: 12px;
  z-index: 100;
  img {
    display: none;
  }
}

.option {
  padding: 8px 20px;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.option:hover {
  background: transparent;
  color: #86ccff;
}

@media (max-width: 886px) {
  .selected-option {
    font-size: 16px;
  }
}
