.confirm-dialog-card {
  border-radius: 20px;
  border: 1px solid #f6c171;
  background: url(https://generation-sessions.s3.amazonaws.com/971f874c2bd17d952d52ab6fd6c3ae29/img/texture.png);
  box-shadow: 0px 4px 21px 0px rgba(154, 154, 154, 0.25);
  margin-top: 52px;
  color: #fff;
  max-width: 1080px;
  margin: 67px auto;
  @media (max-width: 1200px) {
    max-width: 960px;
  }
}

.confirm-btn .MuiButton-text.custom-button {
  padding: 14px 0px;
  border-radius: 20px;
  color: #000;
  width: 100%;
  background: #fff;
  font-family: "Source Sans 3", sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-transform: capitalize;
  border: none;
  min-height: 65px;
  &:hover {
    background-color: #86ccff;
  }
  @media (max-width: 886px) {
    .custom-button {
      font-size: 24px;
    }
  }

  .MuiLoadingButton-loadingIndicatorCenter {
    color: rgba(0, 0, 0, 0.66);
    .MuiCircularProgress-indeterminate {
      height: 22px !important;
      width: 22px !important;
    }
  }
}

.overlap {
  background-image: url(https://generation-sessions.s3.amazonaws.com/971f874c2bd17d952d52ab6fd6c3ae29/img/texture.png);
  background-size: 100% 100%;
  position: relative;
  background-color: rgba(25, 31, 41, 0.4);
  border-radius: 20px;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 20px 30px;
  color: #fff;

  h4 {
    flex: 1;
    text-align: center;
    margin: 0;
    /* Reset margin */
    color: #ffc56f;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }

  .close-icon {
    cursor: pointer;
    z-index: 99;
  }
}

.star-img {
  right: 2px;
  position: absolute;
  top: 105px;
  width: 62px;
}

.star-img2 {
  height: 53px;
  left: 0;
  position: absolute;
  top: 50%;
  width: 45px;
}

.group-9 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 337px;
}

.dialog-content-body {
  padding: 15px 25px 20px 25px;
}

.token-info {
  padding: 0px;
  p {
    margin-bottom: 0;
  }

  .section-with-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 16px;
  }

  .left-content {
    display: flex;
    align-items: center;
  }

  .title {
    margin-right: 8px;
  }

  .separator {
    font-weight: bold;
    margin-right: 4px;
  }

  .right-content {
    display: flex;
    align-items: center;
    .title {
      margin-bottom: 0;
    }
  }
  .icon {
    width: 28px;
    height: 28px;
    margin-left: 8px;
  }
}

.investment-amount {
  color: #fff;
  border-radius: 10px;
  border: 1px solid #636363;
  background: #191f29;
  margin-bottom: 20px;
  padding: 20px 30px;

  h4 {
    color: #fff;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .investment-amount-info {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: "Source Sans 3", sans-serif;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #272e3c;
    margin: 0 0 10px;
    padding: 0 0 10px;
    &:last-child {
      margin: 0;
      padding: 0;
      border-bottom: 0;
    }

    p {
      margin: 0px;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #272e3c;
  margin: 10px 0px;
}

.simulation-date-selection {
  border-radius: 10px;
  border: 1px solid #636363;
  padding: 20px 30px;
  // background-image: url(../../Assets/simulaitonbg.svg);
  // background-size: cover;
  background: #191f29;
  margin-bottom: 0;
  @media (max-width: 886px) {
    margin-bottom: 24px;
  }

  label {
    text-transform: unset !important;
  }

  .type-value {
    display: flex;
    gap: 0;
    justify-content: space-between;
    margin-bottom: 0px;
    .date-div {
      max-width: 48%;
      flex: 0 0 48%;
    }
  }

  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    color: rgb(255 255 255 / 54%) !important;
  }
}
// wip: remove it when custom loader is implemented
.css-pkvtmb-MuiLoadingButton-loadingIndicator {
  left: 68px !important;
}

@media (max-width: 886px) {
  .confirm-dialog-card {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0px;
    margin-top: 8px;
  }

  .overlap {
    background-image: none;
    background-color: transparent;
  }
  .dialog-content-body {
    padding: 0px 0 40px 0;
  }
}

@media (max-width: 426px) {
  .dialog-header {
    padding: 20px;
  }

  .dialog-header h4 {
    font-size: 16px;
  }

  .section-with-icon {
    font-size: 16px !important;
  }

  .star-img {
    display: none;
  }

  .star-img2 {
    display: none;
  }

  .investment-amount h4 {
    font-size: 18px;
  }

  .investment-amount-info {
    font-size: 16px !important;
  }
}
