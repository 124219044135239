.gas-price-sec-title {
  margin-bottom: 15px;
  color: #fff;
  font-family: "Source Sans 3", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: -1px;
  letter-spacing: 0;
  @media (max-width: 767px) {
    font-size: 16px;
    .tooltip-content {
      left: -115px;
      right: 0;
      bottom: 20px;
    }
  }
  .ph-gas-pump-fill {
    height: 25px;
    margin-right: 15px;
  }

  .solar-pin-bold {
    height: 20px;
    margin-left: 6px;
  }
}

.gas-price-lists-box {
  background-color: #131820;
  border: 1px solid;
  border-color: #7a6039;
  border-radius: 20px;
  padding: 0px 16px;

  .list-item {
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 16px 0px;
    border-bottom: 1px solid #272b32;
    text-transform: none;
    cursor: pointer;
    width: 100%;
  }

  .list-item:last-child {
    border-bottom: none;
  }

  .radio-icon {
    width: 24px;
  }

  .radio-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    color: #ffffff;
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  .custom-radio {
    z-index: 99;
    position: relative;
    opacity: 0;
    &:checked + .custom-radio-span {
      background: #6d9dff;
      &:before {
        transform: rotate(45deg);
        height: 14px;
        width: 7px;
        border-bottom: 3px solid #000;
        border-right: 3px solid #000;
        content: "";
        display: block;
        margin-left: 5px;
      }
    }
  }
  .custom-radio-span {
    position: absolute;
    height: 18px;
    width: 18px;
    border: 1px solid #6d9dff;
    border-radius: 50%;
    z-index: 9;
  }
}

.slippage-tolerance {
  margin-top: 42px;
}

.slippage-button {
  margin-top: 42px;
}

.dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 20px 30px;
  color: #fff;
  @media (max-width: 767px) {
    padding: 20px 0;
  }

  h4 {
    flex: 1;
    text-align: center;
    margin: 0;
    /* Reset margin */
    color: #ffc56f;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
  }

  .close-icon {
    position: absolute;
    cursor: pointer;
    z-index: 99;
    @media (max-width: 767px) {
      width: 24px;
    }
  }
}

.tool-tip-container {
  float: right;
}

.slippage-box {
  .radio-text span:not(:last-child) {
    border-right: 1px solid #272b32;
  }
}

@media (max-width: 886px) {
  .confirm-dialog-card {
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0px;
    margin-top: 8px;
  }

  .overlap {
    background-image: none;
    background-color: transparent;
  }
}

@media (max-width: 426px) {
  .token-info {
    padding: 0px;
  }

  .mobile-no-padding.dialog-header {
    padding-left: 0;
    padding-right: 0;
  }
  .dialog-header h4 {
    font-size: 16px;
  }

  .section-with-icon {
    font-size: 16px !important;
  }

  .star-img {
    display: none;
  }

  .star-img2 {
    display: none;
  }

  .investment-amount h4 {
    font-size: 18px;
  }

  .investment-amount-info {
    font-size: 16px !important;
  }
}

.radio-text span.selected {
  background-color: #86ccff;
  border-radius: 6px;
  /* Change to the desired selected background color */
  color: #000;
  /* Change to the desired selected text color */
}

.Slippage-sec {
  span {
    padding: 4px 0px;
    cursor: pointer;
    flex: 1 1;
    text-align: center;
  }
}

.slippage-atuo-select {
  p {
    color: #7685a7;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 5px;
  }
}

.overlap-group .confirm-dialog-card .MuiDialogContent-root {
  overflow: visible;
}
