.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #898989;
  background: #131820;
  padding: 7px 10px 8px 10px;
  &.r-10 {
    border-radius: 10px;
  }

  .custom-input {
    flex: 1;
    background: none;
    border: none;
    color: #fff;
    padding: 5px;
    font-size: 16px;
    outline: none;
  }

  label {
    margin-right: 10px;
    color: #fff;
  }
}
