.tooltip-container {
  position: relative;
  display: inline-block;
  /* Set z-index and positioning for the tooltip container */
  z-index: 100;
}

.tooltip-icon {
  /* ... other styles ... */
  cursor: pointer;
  color: #fff;
}

.tooltip-content {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #131820;
  border: 1px solid #23282f;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 100;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  font-family: "Source Sans 3", sans-serif;
  width: 250px;

  p {
    margin-bottom: 0px;
  }
}

.tooltip-container:hover .tooltip-content {
  display: block;
}

.tooltip-message {
  margin-top: 4px;
}

// @media (max-width: 886px) {
//     .tooltip-content {
//         left: 73%;
//         /* top: 0; */
//         bottom: -245%;
//         transform: translateX(-110%);
//     }
// }
