@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;300;400;500;600;700&family=Raleway:wght@900&family=Source+Sans+3:wght@200;300;400;500;600;700;800;900&display=swap");

// #root > div > div {
//   background-color: #131820;
// }

body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131820 !important;
  color: #ffffff;
}
img {
  max-width: 100%;
}

.w3m-overlay {
  z-index: 99;
}
