.analyse-listing {
  margin: 0 -15px;
  @media (max-width: 886px) {
    margin: 0;
  }
}
.analyse-card-outer {
  max-width: 50%;
  width: 100%;
  padding: 5px 15px;
  .analyse-container {
    border: 1px solid #ffc670;
    border-radius: 20px;
    background: #191f29;
    padding: 24px;
    box-shadow: 0 4px 15px rgba(154, 154, 154, 0.65);
  }
  .live-updates {
    position: relative;
    p {
      font-size: 15px;
      color: #fff;
      font-weight: 600;
      font-family: "Source Sans 3", sans-serif;
    }
    span {
      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        left: 0;
        top: 4px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
      &:after {
        content: " ";
        position: absolute;
        top: 4px;
        z-index: 1;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
        // animation: ripple 1s ease 0s infinite normal;
      }
    }
    &.active {
      span {
        &:before {
          background-color: #198754;
        }
        &:after {
          background-color: #198754;
        }
      }
    }
    &.cancelled {
      span {
        &:before {
          background-color: #ff4200;
        }
        &:after {
          background-color: #ff4200;
        }
      }
    }
    &.completed {
      span {
        &:before {
          background-color: #565656;
        }
      }
    }
    &.historical {
      span {
        &:before {
          background-color: #cae213;
        }
        &:after {
          background-color: #cae213;
        }
      }
    }
  }
  .button-div {
    button {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.49);
      border-radius: 4px;
      background: transparent;
      padding: 7px 15px;
      height: 34px;
      margin-right: 8px;
      &:hover {
        background: #fff;
        border-color: #fff;
        color: #333d4f;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
  .anchor-link {
    background: #333d4f;
    border-radius: 50%;
    color: #fff;
    height: 34px;
    width: 34px;
    text-align: center;
    &:hover {
      background: #fff;
      color: #191f29;
    }
  }
  .card-body-content {
    h2 {
      font-size: 20px;
      color: #fff;
      margin: 10px 0 0;
      font-family: "Source Sans 3", sans-serif;
      font-weight: 600;
      span {
        font-size: 12px;
        &.down-arrow {
          color: #ff3d3d;
          i {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ff3d3d;
            display: inline-block;
            margin: 0 5px;
          }
        }
        &.up-arrow {
          color: #3dff67;
          i {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            display: inline-block;
            margin: 0 5px;
            border-bottom: 5px solid #3dff67;
          }
        }
      }
    }
    .analyse-inner-listing {
      li {
        list-style: none;
        border-bottom: 1px solid #272e3c;
        padding: 15px 0;
        &:last-child {
          border-bottom: 0;
        }
        .token-list {
          h3 {
            margin-left: 16px;
            display: flex;
            align-items: center;
            img {
              max-width: 24px;
              margin-right: 5px;
            }
          }
        }
        p {
          font-size: 14px;
          color: #97a2b6;
          margin: 0;
        }
        h3 {
          text-wrap: nowrap;
          font-size: 16px;
          margin: 0;
          color: #fff;
          font-weight: 600;
        }
      }
    }
  }
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 9px;
    opcity: 75;
    width: 0;
    height: 0;
  }
  100% {
    left: -18px;
    top: -14px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 886px) {
  .analyse-card-outer {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    .analyse-container {
      padding: 12px;
      .button-div {
        button {
          padding: 7px 10px;
          font-size: 10px;
        }
      }
    }
  }
}
