.strategy-info {
  .button-div {
    button {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.49);
      border-radius: 4px;
      background: transparent;
      padding: 7px 15px;
      height: 38px;
      margin-left: 8px;
      &:nth-child(3) {
        min-width: 86px;
        @media (max-width: 886px) {
          min-width: auto;
        }
      }
      @media (max-width: 886px) {
        font-size: 0;
        padding: 7px 0px;
        text-align: center;
        height: 40px;
        width: 40px;
        margin-right: 0;
        margin-left: 8px;
        margin-top: 12px;
      }
      &:hover {
        background: #fff;
        border-color: #fff;
        color: #333d4f;
      }
      &.bg-normal {
        min-width: 86px;
        @media (max-width: 886px) {
          min-width: 40px;
        }
      }
      &.bg-white {
        min-width: 86px;
        @media (max-width: 886px) {
          min-width: 40px;
        }
        .MuiCircularProgress-root {
          color: #333d4f;
          &:hover {
            color: #333d4f;
          }
        }
      }
      .MuiCircularProgress-root {
        height: 20px !important;
        width: 20px !important;
        color: #fff;
        &:hover {
          color: #333d4f;
        }
        svg {
          margin-right: 0;
          display: inline-block;
        }
      }
      svg {
        margin-right: 5px;
        max-width: 20px;
        max-height: 20px;
        @media (max-width: 886px) {
          margin-right: 0;
        }
      }
    }
  }
  .card-header {
    .live-updates {
      position: relative;
      p {
        font-size: 15px;
        color: #fff;
        font-weight: 600;
        font-family: "Source Sans 3", sans-serif;
      }
      span {
        &:before {
          content: " ";
          position: absolute;
          z-index: 2;
          left: 0;
          top: 4px;
          width: 15px;
          height: 15px;
          background-color: #ff4200;
          border-radius: 50%;
        }
        &:after {
          content: " ";
          position: absolute;
          top: 4px;
          z-index: 1;
          width: 15px;
          height: 15px;
          background-color: #ff4200;
          border-radius: 50%;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
          animation: ripple 1s ease 0s infinite normal;
        }
      }
      &.active {
        span {
          &:before {
            background-color: #198754;
          }
          &:after {
            background-color: #198754;
          }
        }
      }
      &.cancelled {
        span {
          &:before {
            background-color: #ff4200;
          }
          &:after {
            background-color: #ff4200;
          }
        }
      }
      &.completed {
        span {
          &:before {
            background-color: #565656;
          }
          // &:after {
          //   background-color: #565656;
          // }
        }
      }
      &.historical {
        span {
          &:before {
            background-color: #cae213;
          }
          &:after {
            background-color: #cae213;
          }
        }
      }
    }
  }
  .card-body-content {
    h2 {
      font-size: 20px;
      color: #fff;
      margin: 10px 0 0;
      font-family: "Source Sans 3", sans-serif;
      font-weight: 600;
      span {
        font-size: 13px;
        &.down-arrow {
          color: #ff3d3d;
          i {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ff3d3d;
            display: inline-block;
            margin: 0 5px;
          }
        }
        &.up-arrow {
          color: #3dff67;
          i {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            display: inline-block;
            margin: 0 5px;
            border-bottom: 5px solid #3dff67;
          }
        }
      }
    }
    .analyse-inner-listing {
      li {
        list-style: none;
        border-bottom: 1px solid #272e3c;
        padding: 15px 0;
        &:last-child {
          border-bottom: 0;
        }
        .token-list {
          h3 {
            margin-left: 16px;
            display: flex;
            align-items: center;
            img {
              max-width: 24px;
              margin-right: 5px;
            }
          }
        }
        p {
          font-size: 14px;
          color: #97a2b6;
          margin: 0;
        }
        h3 {
          font-size: 16px;
          margin: 0;
          color: #fff;
          font-weight: 600;
        }
        .tooltip-container {
          cursor: pointer;
          .tooltip-icon {
            span {
              color: #97a2b6;
              &:hover {
                color: #fff;
              }
            }
          }
          .tooltip-content {
            width: max-content;
            padding: 10px;
            @media (max-width: 992px) {
              left: -24px;
              right: 0;
            }
          }
          &:hover {
            ~ .outer-hover-div {
              display: block;
            }
          }
        }
        .outer-hover-div {
          position: absolute;
          background: rgba(19, 24, 32, 1);
          padding: 10px;
          border-radius: 10px;
          display: none;
          top: 40px;
          border: 1px solid rgba(131, 131, 131, 0.3);
          bottom: auto;
          width: auto;
          @media (max-width: 992px) {
            right: 0;
            background: rgba(35, 42, 54, 1);
          }
          h4 {
            font-size: 14px;
            font-weight: 600;
            color: rgb(151, 162, 182);
          }
          p {
            color: #fff;
            visibility: hidden;
            height: 0;
            margin: 0;
            font-size: 13px;
            word-wrap: break-word;
          }
          textarea {
            white-space: nowrap;
            width: max-content;
            height: 21px;
            font-size: 13px;
            width: 100%;
            resize: none;
            &:focus,
            &:active,
            &:focus-visible {
              border: 0;
              outline: 0;
              box-shadow: none;
            }
          }
        }
      }
    }
    .para-text {
      margin: 12px 0;
      h3 {
        font-size: 20px;
        font-weight: 600;
        font-family: "IBM Plex Mono", Helvetica;
        margin: 0;
      }
      h4 {
        font-size: 18px;
        font-family: "IBM Plex Mono", Helvetica;
        font-weight: 600;
        margin: 0;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        font-family: "IBM Plex Mono", Helvetica;
        img {
          max-width: 22px;
        }
      }
    }
  }
}

.card-header-bottom {
  background: #fff;
  border-radius: 12px;
  border: 1px solid #fff;
  padding: 12px 20px;
  font-size: 20px;
  font-weight: 600;
  &:hover {
    background: #86ccff;
    border-color: #86ccff;
  }
}

@media (max-width: 886px) {
  .strategy-info {
    .card-body-content {
      .para-text {
        margin: 12px 0;
        h3 {
          font-size: 16px;
        }
        h4 {
          font-size: 14px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}

.wallet-container-outer {
  .filter-div {
    .dropdown-select {
      max-width: 100%;
      .selected-option {
        .placeholder {
          opacity: 1;
          img {
            display: inline-block;
            max-width: 22px;
            max-height: 22px;
          }
          &.first-selection {
            background: #131820 url(../../components/Assets/live-svg.svg)
              no-repeat;
            padding-left: 32px;
            background-position: left center;
          }
        }
      }
      .options-list {
        .option {
          padding: 8px 15px;
          img {
            display: inline-block;
            width: 24px;
          }
        }
      }
    }
    .select-input-style {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 15px !important;
    }
    .custom-input {
      position: relative;
      .dropdown-select {
        position: absolute;
        top: 37px;
        right: 17px;
        max-width: 180px;
        @media (max-width: 560px) {
          max-width: 160px;
        }
        .selected-option {
          background: rgb(47, 57, 74);
          padding: 8px 15px;
          border: 0;
          @media (max-width: 560px) {
            font-size: 14px;
          }
          .placeholder {
            background: rgb(47, 57, 74);
            font-size: 14px;
            cursor: pointer;
          }
        }
        .options-list {
          .option {
            font-size: 14px;
            padding: 8px 15px;
          }
        }
      }
    }
  }
}

.network-text {
  background: rgba(35, 42, 54, 1);
  border: 1px solid rgba(255, 255, 255, 0.49);
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 12px;
}

.edit-strategy-info {
  .type-value {
    gap: 0;
    .select-container {
      max-width: 48%;
      flex: 0 0 48%;
      &.full-width {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .icon-container {
        padding: 12px 20px;
        border: 1px solid rgba(255, 255, 255, 0.49);
        border-radius: 6px;
      }
      .custom-form {
        border: 1px solid rgba(255, 255, 255, 0.49);
        border-radius: 6px;
        padding: 12px 20px;
        color: rgba(166, 166, 166, 1);
        width: 100%;
      }
      .dropdown-select {
        .selected-option {
          padding: 12px 20px;
        }
      }
    }
  }
}
