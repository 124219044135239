.custom-datepicker {
  width: 100%;
  background-color: #131820;
  border: 0.6px solid;
  border-color: #ffc97a;
  border-radius: 12px;
  min-width: unset;

  label {
    color: #ffffff;
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  label.Mui-focused {
    border: none;
  }

  input {
    color: #fff;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  input:hover {
    border: none;
    outline: none;
  }

  label.MuiFormLabel-filled.MuiInputLabel-outlined.MuiInputLabel-animated {
    display: none !important;
  }

  .MuiPickersCalendar-root {
    border: none;
  }

  .MuiPickersCalendar-root {
    background-color: #000 !important;
    columns: #fff;
  }

  .MuiPickersModal-dialogRoot {
    background-color: #080808 !important;
  }

  .MuiPickersModal-dialogRoot {
    border: 2px solid #0b0b0b;
  }

  .MuiInputBase-root.MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .MuiInputBase-root.MuiOutlinedInput-root:hover {
    box-shadow: none;
  }

  .MuiInputBase-root.MuiOutlinedInput-root:focus-within
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .MuiInputBase-root.MuiOutlinedInput-root:focus-within {
    box-shadow: none;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: "Source Sans 3", sans-serif !important;
  }

  .MuiInputAdornment-sizeMedium {
    display: none;
  }
}

.date-picker-title {
  margin-bottom: 6px;
  color: #777;
  font-family: "Source Sans 3", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.custom-datepicker-icon {
  margin-right: 10px;
  /* Adjust as needed */
}

.custom-datepicker-icon img {
  width: 24px;
  /* Adjust the icon size */
  height: 24px;
}

.custom-datepicker-icon-button {
  background-color: transparent;
  border: none;
  padding-right: 10px;
}

// .MuiPaper-root.MuiDialog-paper {

//     color: #fff !important;
// }

.custom-datepicker .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

@media (max-width: 886px) {
  .css-hz1bth-MuiDialog-container {
    margin-top: 0px !important;
  }

  // /* Change the color of the day numbers */
  // .MuiPickersDay-root {
  //     color: #fff !important;
  // }

  // /* Change the color of the navigation arrows */
  // .MuiPickersArrowSwitcher-iconButton {
  //     color: #fff !important;
  // }

  // .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  //     color: #fff !important;
  // }

  // .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  //     color: #fff !important;
  // }

  // .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  //     color: #fff !important;
  // }

  // /* Change the color of the weekday labels */
  // .MuiDayCalendar-weekDayLabel {
  //     color: #fff !important;
  // }

  /* Change the padding of the content inside MuiDialogContent */
  .MuiDialogContent-root {
    padding: 0px !important;
    /* Adjust the value as needed */
  }

  // .css-1hbyad5-MuiTypography-root {
  //     color: #fff !important;
  // }

  /* Change the styles of the footer buttons in MuiDialogActions */
  .MuiDialogActions-root {
    padding: 16px;
    /* Adjust padding as needed */
    justify-content: space-between;
    /* Align buttons to both ends */
  }

  /* Change the styles of the "Cancel" button */
  .MuiDialogActions-root
    .MuiButton-root.MuiButton-text.MuiButton-textPrimary:first-child {
    color: #86ccff;
    /* Change text color */
  }

  /* Change the styles of the "OK" button */
  .MuiDialogActions-root
    .MuiButton-root.MuiButton-text.MuiButton-textPrimary:last-child {
    color: #86ccff;
    /* Change text color */
  }
}
