/* CustomCheckbox.css */
/* ... Existing styles ... */

.checkbox-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}

.checkmark-icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: #fff;
  /* Change the color as needed */
}

/* CustomCheckbox.css */
.custom-checkbox {
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  width: fit-content;
  color: #fff;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #222936;
  margin-right: 8px;
  position: relative;
  display: inline-flex;
  padding: 14px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1.5px solid #fff;
}

.checkmark-icon {
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.checkbox-input:checked ~ .checkbox-icon {
  background-color: #45affb;
  border-color: #45affb;
}

.checkbox-input:checked ~ .checkmark-icon {
  display: block;
}
