.wallet-container-outer {
  max-width: 900px;
  margin: 0 auto;
  padding: 0px 0px;
  position: relative;

  .dropdown-select {
    max-width: 180px;
    text-align: left;
    color: #fff;
    .selected-option {
      padding: 12px 15px;
      .placeholder {
        color: #fff;
      }
    }
  }
  .wallet-header {
    h1 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin: 0;
      font-family: "Source Sans 3", sans-serif;
      @media (max-width: 886px) {
        font-size: 26px;
      }
    }

    .mobile-btn {
      border-radius: 10px;
      padding: 8px 20px;
      font-family: "Source Sans Pro", Helvetica;
      font-size: 16px;
      transition: 0.2s all ease-in-out;
      font-weight: 600;
      background: #fff;
      color: #1b283c;
      border: 1px solid #fff;

      &:hover {
        opacity: 0.9;
      }

      svg {
        max-width: 16px;
      }
    }
  }

  .wallet-listing {
    margin: 30px -15px 0;
    @media (max-width: 992px) {
      margin: 30px 0 0;
    }

    .wallet-outer {
      max-width: 33.333%;
      padding: 0 15px;
      margin: 0 0 30px;
      width: 100%;

      .wallet-container {
        border-radius: 10px;
        border: 1px solid #4e5f7a;
        padding: 16px;
        background: #1b283c url(../../Assets/bg-img.jpg) no-repeat;
        background-size: cover;

        .wallet-header {
          img {
            max-width: 38px;
            max-height: 38px;
          }

          .custom-white-button {
            background: #2c395a;
            border: 1px solid rgba(255, 255, 255, 0.7);
            color: #fff;
            padding: 4px 7px;
            border-radius: 4px;
            font-family: "Source Sans 3", sans-serif;
            font-weight: 600;
            font-size: 10px;

            &:hover {
              background: #fff;
              color: #1f2632;
              border: 1px solid #fff;
            }
          }
        }

        .wallet-content {
          ul {
            li {
              max-width: 50%;
              width: 100%;
              margin: 5px 0 0px;
              list-style: none;

              p {
                margin: 0 0 5px;
                font-size: 12px;
                color: #8596b1;
              }

              h2 {
                font-size: 20px;
                font-family: "Source Sans 3", sans-serif;
                font-weight: 600;
                margin: 0;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
.empty-div-portfolio {
  padding: 40px 0;
  h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0;
  }
  p {
    font-size: 18px;
    color: #909aa9;
    max-width: 50%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 886px) {
      max-width: 100%;
    }
  }
}

@media (max-width: 886px) {
  .wallet-container-outer {
    margin: 0px auto 20px;
    padding: 0;
    max-width: 100%;

    .wallet-listing {
      .wallet-outer {
        max-width: 100%;
        padding: 0 0px;
        margin: 0 0 20px;
      }
    }
  }
}

@media (max-width: 640px) {
  .wallet-header {
    h1 {
      font-size: 24px;
    }
  }
}

.amount-div input {
  padding: 10px 10px 10px 10px !important;
}
