.custom-button {
  padding: 14px 0px;
  border-radius: 20px;
  color: #000;
  width: 100%;
  background: #fff;
  font-family: "Source Sans 3", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  border: none;
}

.custom-button:hover {
  background-color: #86ccff;
}

@media (max-width: 886px) {
  .custom-button {
    font-size: 24px;
  }
}
