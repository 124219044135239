.tab-switch {
  display: flex;
  border-radius: 10px;
  width: max-content;
  border-radius: 10px;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    justify-content: space-between;
    .tab-option {
      margin: 0;
    }
  }
}

.tab-option {
  border-radius: 12px;
  font-family: "Source Sans 3", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0px;
  padding: 12px 12px;
  color: #fff;
  background: #1e242e;
  border: 1px solid #1e242e;
  transition: background-color 0.2s;
  width: 32%;
  p {
    font-size: 12px;
    color: #aebcd1;
    margin: 0;
    text-align: left;
    text-transform: none;
    max-width: 100%;
  }
}

.tab-option.selected {
  border: 1px solid #fff;
}

@media (max-width: 886px) {
  .tab-option {
    font-size: 14px;
  }
}
