.support-container {
  border: 1px solid #768092;
  border-radius: 20px;
  background-color: #191f29;
  padding: 30px;
  @media (max-width: 886px) {
    padding: 20px;
  }
  .support-header {
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    h2 {
      font-size: 18px;
      font-weight: 700;
      font-family: "Source Sans 3", sans-serif;
      margin: 0;
    }
  }
  .slick-slider {
    margin: 0 -15px;
    .slick-prev {
      left: auto;
      top: -58px;
      right: 95px;
      @media (max-width: 886px) {
        right: 65px;
      }
      &::before {
        content: "";
      }
      svg {
        color: #fff;
        @media (max-width: 886px) {
          max-width: 24px;
        }
      }
      &.slick-disabled {
        opacity: 0.4;
      }
    }
    .slick-next {
      right: 30px;
      top: -58px;
      &:before {
        content: "";
      }
      svg {
        color: #fff;
        @media (max-width: 886px) {
          max-width: 24px;
        }
      }
    }
    .support-inner-div {
      background: #131820;
      border: 1px solid #2e3642;
      border-radius: 10px;
      padding: 16px;
      p {
        font-size: 14px;
        color: #687d9f;
        font-family: "Source Sans 3", sans-serif;
      }
      svg {
        color: #687d9f;
      }
      .support-body-content {
        p {
          font-size: 12px;
          color: #94bdff;
        }
        h3 {
          font-size: 24px;
          color: #fff;
          margin: 5px 0;
          font-family: "Source Sans 3", sans-serif;
          font-weight: 700;
        }
      }
    }
  }
}

.bg-container {
  border: 1px solid #768092;
  border-radius: 20px;
  background-color: #191f29;
  padding: 20px;
  .bg-header {
    h2 {
      font-size: 20px;
      color: #f5f5f5;
      font-weight: 600;
      font-family: "IBM Plex Mono", Helvetica;
      margin: 0;
    }
  }
  span {
    color: #737373;
    font-size: 18px;
    font-family: "IBM Plex Mono", Helvetica;
    &.value-text {
      position: absolute;
      bottom: 60px;
      left: 0;
      right: 0;
      font-family: "Source Sans 3", sans-serif;
      font-size: 40px;
      font-weight: 700;
      color: #fff;
    }
  }
  .semicircle-container {
    margin: 20px 0 0;
    text-align: center;
    .semicircle-percent-value {
      font-size: 38px;
      color: #fff;
      font-weight: 700;
      font-family: "Source Sans 3", sans-serif;
    }
  }
}

.cheet-outer-container {
  padding: 0;
  .analyse-container {
    border-color: #768092;
    box-shadow: none;
    .card-header {
      h2 {
        font-size: 18px;
        color: #fff;
        font-weight: 700;
        font-family: "Source Sans 3", sans-serif;
        margin: 0;
        img {
          max-width: 24px;
        }
      }
    }
    .card-body-content {
      h2 {
        span {
          font-size: 14px;
          margin: 0 10px;
          i {
            margin-right: 8px;
          }
        }
      }
      ul {
        li {
          h3 {
            span {
              font-size: 12px;
              margin-right: 8px;
              display: inline-block;
              &.down-arrow {
                color: #ff5959;
                i {
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  border-top: 5px solid #ff3d3d;
                  display: inline-block;
                  margin: 0 5px;
                }
              }
              &.up-arrow {
                color: #3dff67;
                i {
                  width: 0;
                  height: 0;
                  border-left: 5px solid transparent;
                  border-right: 5px solid transparent;
                  display: inline-block;
                  margin: 0 5px;
                  border-bottom: 5px solid #3dff67;
                }
              }
            }
          }
        }
      }
    }
  }
}
.column-card-div {
  .bg-container {
    p {
      font-size: 18px;
      color: #89909c;
      font-weight: 600;
      margin: 0 0 16px;
      font-family: "Source Sans 3", sans-serif;
    }
    h3 {
      font-size: 28px;
      font-family: "Source Sans 3", sans-serif;
      margin: 0 0 16px;
      color: #fff;
      font-weight: 600;
    }
    span {
      background: rgba(255, 194, 194, 0.13);
      border-radius: 8px;
      padding: 5px 12px;
      font-family: "Source Sans 3", sans-serif;
      font-size: 18px;
      font-weight: 400;
      color: #ff5959;
    }
  }
}
