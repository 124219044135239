.pagination.custom-pagination {
  .page-item {
    &:first-child {
      .page-link {
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 1);
        padding: 0;
        height: 34px;
        width: 34px;
        display: flex;
        margin-right: 15px;
        align-items: center;
        justify-content: center;
        span:not(.visually-hidden) {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='1' fill-rule='evenodd' clip-rule='evenodd' d='M0.229065 6.09427C0.0823949 5.93934 -2.26405e-07 5.72923 -2.35981e-07 5.51015C-2.45557e-07 5.29107 0.0823948 5.08097 0.229065 4.92603L4.65489 0.252229C4.72706 0.173318 4.81339 0.110377 4.90884 0.0670765C5.0043 0.0237763 5.10696 0.000984514 5.21084 3.12287e-05C5.31472 -0.000922057 5.41774 0.0199824 5.51389 0.0615243C5.61004 0.103066 5.69739 0.164414 5.77085 0.241988C5.84431 0.319562 5.9024 0.411808 5.94174 0.513345C5.98108 0.614882 6.00087 0.723675 5.99997 0.833377C5.99907 0.943079 5.97749 1.05149 5.93648 1.15229C5.89548 1.25309 5.83588 1.34426 5.76115 1.42047L1.88846 5.51015L5.76115 9.59983C5.90367 9.75566 5.98253 9.96435 5.98074 10.181C5.97896 10.3976 5.89668 10.6048 5.75162 10.758C5.60657 10.9112 5.41034 10.9981 5.20521 11C5.00008 11.0019 4.80245 10.9186 4.65489 10.7681L0.229065 6.09427Z' fill='white'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          font-size: 0;
          width: 10px;
          height: 10px;
        }
      }
    }
    &:last-child {
      .page-link {
        border-radius: 50%;
        margin-left: 15px;
        border: 1px solid rgba(255, 255, 255, 1);
        padding: 0;
        height: 34px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        span:not(.visually-hidden) {
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='11' viewBox='0 0 6 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.77093 4.90573C5.91761 5.06066 6 5.27077 6 5.48985C6 5.70893 5.91761 5.91903 5.77093 6.07397L1.34511 10.7478C1.27294 10.8267 1.18661 10.8896 1.09116 10.9329C0.995705 10.9762 0.893043 10.999 0.789161 11C0.68528 11.0009 0.582259 10.98 0.486109 10.9385C0.38996 10.8969 0.302607 10.8356 0.229149 10.758C0.15569 10.6804 0.0975976 10.5882 0.0582597 10.4867C0.0189218 10.3851 -0.000873195 10.2763 2.95033e-05 10.1666C0.000932202 10.0569 0.0225147 9.94851 0.0635175 9.84771C0.10452 9.74691 0.164122 9.65574 0.238846 9.57953L4.11154 5.48985L0.238846 1.40017C0.0963318 1.24434 0.0174741 1.03565 0.0192566 0.819019C0.0210391 0.602393 0.103319 0.395172 0.248376 0.241988C0.393432 0.0888042 0.589659 0.00191304 0.794792 3.04828e-05C0.999925 -0.00185208 1.19755 0.0814247 1.34511 0.231923L5.77093 4.90573Z' fill='white'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          font-size: 0;
          width: 10px;
          height: 10px;
        }
      }
    }
    &.active {
      .page-link {
        color: #fff;
        background: rgba(255, 255, 255, 0.03);
        border-radius: 5px;
      }
    }
    .page-link {
      background: rgba(255, 255, 255, 0.03);
      border-radius: 5px;
      border: 0;
      margin: 0 5px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.5);
      &:focus{
        box-shadow: none;
      }
      &:hover{
        color:#fff;
      }
    }
  }
}
