/* AmountInputWithToggle.scss */

.custom-amount-input input {
  font-size: 24px;
  font-weight: 600;
  max-width: 60%;
}

.amount-input-unit {
  width: 100%;
  display: flex;
  flex-direction: column;

  .unit-box {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    width: 72px;
    border-radius: 10px;
    background: #262d39;
    padding: 6px 8px;
  }

  .unit-box div {
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
  }

  .input-container {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    border-radius: 20px;
    border: 1px solid #898989;
    background: #191f29;
    padding: 7px 10px 7px 10px !important;
  }

  label {
    margin-bottom: 6px;
    color: #777;
    font-family: "Source Sans 3", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  input {
    flex: 1;
    padding: 10px;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    font-family: IBM Plex Mono;

    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="number"],
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: textfield;
  }
}

/* Add your ToggleSwitch styles as needed */
