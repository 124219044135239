.footer-container {
  background: #181e27;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 14px 0;
  .copyright {
    padding-left: 24px;
  }
  p {
    opacity: 0.6;
    font-size: 14px;
    color: #fff;
    margin: 0;
    @media (max-width: 992px) {
      font-size: 14px;
      margin: 14px 0 0;
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center;
    }
  }
  .footerMenu-links {
    @media (max-width: 992px) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      max-width: 100%;
      flex: 0 0 100%;
      padding-bottom: 14px;
    }
    h2 {
      font-size: 14px;
      color: #fff;
    }
    ul {
      @media (max-width: 992px) {
        justify-content: space-between;
      }
      li {
        list-style: none;
        display: inline-block;
        padding: 0px 20px;
        border-right: 1px #2b3443 solid;
        @media (max-width: 992px) {
          flex: 0 0 33.33%;
          padding: 0;
          text-align: center;
          &:first-child {
            text-align: left;
          }
        }
        &:last-child {
          border-right: 0;
        }
        a {
          font-size: 14px;
          color: #79859a;
          @media (max-width: 992px) {
            font-size: 14px;
          }

          &:hover{
            color: #fff;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
