.select-input-style {
  border-radius: 10px !important;
  border: 1px solid #898989 !important;
  background: #131820 !important;
  /* align-items: normal; */
  padding: 10px 20px !important;
  width: 100% !important;
  text-align: left !important;
  /* align-items: end; */
  color: #fff !important;
  font-family: "Source Sans 3", sans-serif !important;
  text-transform: none !important;
  font-size: 14px !important;

  @media (max-width: 886px) {
    padding: 10px 5px !important;
  }
}

.dialog-style {
  .MuiDialogContent-root {
    padding-top: 0px !important;
  }

  .MuiDialogContent-root.token-select-dialog {
    overflow: hidden;
  }
  .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
    overflow: hidden;
  }

  .MuiPaper-elevation24.MuiDialog-paperFullWidth {
    border-radius: 20px;
    border: 1px solid #f6c171;
    background: #191f29;
    box-shadow: 0px 4px 21px 0px rgba(154, 154, 154, 0.25);
    // margin-top: 52px;
    padding: 25px 40px;
    color: #fff;
    max-width: 530px;
    height: 100vh;
    overflow: hidden !important;
    @media (max-width: 767px) {
      padding: 25px 20px;
      .css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
        padding: 10px 5px;
      }
    }
    @media (max-width: 420px) {
      padding: 15px 10px;
    }
  }

  .css-hz1bth-MuiDialog-container {
    background-color: transparent;
    backdrop-filter: blur(0px);
    // height: auto !important;
    // margin-top: 123px;
  }
}

.icon-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: pre;
  .token-inner-icon {
    width: 22px;
    height: 22px;
    border: 1px solid rgba(255, 255, 255);
    border-radius: 50px;
    img {
      max-width: 16px;
      @media (max-width: 886px) {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 886px) {
    img {
      margin-left: 4px;
    }
  }
  @media (max-width: 360px) {
    font-size: 12px;
    padding: 0;
  }
}

.custom-search-input {
  display: flex;
  align-items: center;
  border: 1px solid #7a603a;
  background: #131820;
  border-radius: 20px;
  padding: 8px;
  width: 100%;
}

.search-icon {
  color: white !important;
  margin-right: 8px;
}

.search-textfield-root {
  flex: 1;
}

.search-textfield-input {
  border: none;
  outline: none;
  background: transparent;
  color: white;
  width: 100%;
}

.header-select {
  display: flex;

  img {
    cursor: pointer;
  }

  h2 {
    color: #ffc670;
    text-align: center;
    font-family: "IBM Plex Mono", Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin: 0 auto;
    p {
      font-weight: 400;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.token-select-dialog {
  input {
    font-family: "Source Sans 3", sans-serif !important;
    color: #fff;
  }

  .css-1ejo8xl-MuiFormControl-root-MuiTextField-root
    .MuiOutlinedInput-root:focus-visible {
    border: 1px solid #7a603a;
  }
}

.search-placeholder {
  color: white !important;
  @media (max-width: 420px) {
    min-width: 30px;
  }
}

.token-lists {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background: linear-gradient(
    270deg,
    rgba(34, 42, 54, 0) 0%,
    #222a36 45.83%,
    rgba(34, 42, 54, 0) 100%
  );
  margin: 10px 0px;
  .token-icon {
    .token-inner-icon {
      width: 38px;
      height: 38px;
      border: 1px solid rgba(255, 255, 255);
      border-radius: 50px;
      margin-right: 27px;
    }
  }
  @media (max-width: 420px) {
    .token-icon {
      .css-cveggr-MuiListItemIcon-root {
        min-width: 30px;
        img {
          height: 28px !important;
          width: 28px !important;
        }
      }
    }
  }
}

.token-label {
  flex: 1;
  display: flex;
  flex-direction: column;

  h5 {
    color: #fff;
    font-family: "Source Sans 3", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
  }

  small {
    color: #6b88b5;
    font-family: "Source Sans 3", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.right-icon {
  margin-left: auto;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  opacity: 1;
}

.select-opt-section {
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow: auto;
}

/* Style the scrollbar track */
.select-opt-section::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width of the scrollbar */
  height: 50px !important;
  /* Set the height of the scrollbar */
}

/* Style the scrollbar thumb */
.select-opt-section::-webkit-scrollbar-thumb {
  background-color: #222a36;
  /* Change the color of the thumb */
  border-radius: 5px;
  /* Round the corners of the thumb */
}

/* Style the scrollbar thumb on hover */
.select-opt-section::-webkit-scrollbar-thumb:hover {
  background-color: #222a36;
  /* Change the color of the thumb on hover */
}

/* Style the scrollbar track when it's being dragged */
.select-opt-section::-webkit-scrollbar-thumb:active {
  background-color: #222a36;
  /* Change the color of the thumb when active */
}

/* Style the scrollbar track (the empty space between thumb and track) */
.select-opt-section::-webkit-scrollbar-track {
  background-color: transparent;
  /* Change the color of the track */
  border-radius: 5px;
  /* Round the corners of the track */
}

@media (max-width: 886px) {
  .css-hz1bth-MuiDialog-container {
    margin-top: 90px !important;
  }

  .select-opt-section {
    height: 93%;
    overflow: scroll;
  }
}

@media (max-width: 426px) {
  .dialog-style .MuiPaper-elevation24 {
    padding: 25px 24px;
    margin-top: 16px;
    width: 90%;
  }
  .select-opt-section {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 90%;
  }
}
@media (min-height: 900px) {
  .dialog-style .MuiPaper-elevation24 {
    max-height: 75vh;
  }
}
