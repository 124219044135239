.trade-table {
  background: #191f29;
  border: 1px solid rgba(180, 223, 255, 0.4);
  box-shadow: rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  border-spacing: 0;
  border-collapse: separate;
  thead {
    tr {
      th {
        background: #131820;
        font-size: 16px;
        color: #97a2b6;
        padding: 22px 14px;
        font-family: "Source Sans 3", sans-serif;
        font-weight: 400;
        border: 0;
        border-bottom: 1px solid rgba(180, 223, 255, 0.5);
      }
    }
  }
  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
      td {
        padding: 14px 14px;
        border: 0;
        font-size: 16px;
        border-bottom: 1px solid rgba(180, 223, 255, 0.5);
        color: #fff;
        font-family: "Source Sans 3", sans-serif;
        font-weight: 400;
        img {
          max-width: 22px;
          margin: 0 0 5px;
        }
        div {
          p {
            font-size: 12px;
            margin: 0;
          }
        }
        span {
          border: 1px solid;
          border-radius: 8px;
          padding: 4px 10px;
          font-size: 13px;
          i {
            width: 0;
            height: 0;
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
          }
          &.buy {
            color: #ff7f37;
            border-color: #ff7f37;
            i {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #ff7f37;
            }
          }
          &.sell {
            color: #1bcf83;
            border-color: #1bcf83;
            i {
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-bottom: 6px solid #1bcf83;
            }
          }
          &.floor {
            color: #78b6ff;
            border-color: #78b6ff;
            i {
              border-top: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-left: 6px solid#78b6ff;
            }
          }
        }
      }
    }
  }
}

.trade-outer {
  .analyse-container {
    border-color: rgba(41, 49, 62, 1);
    box-shadow: none;
    .view-less-btn {
      display: none;
      border-radius: 6px;
      background: #11161f;
      border: 1px solid #505d73;
      color: #a6c7ff;
      font-size: 12px;
      padding: 8px 12px;
      margin-left: auto;
    }
    .view-more-btn {
      background: #11161f;
      padding: 8px 12px;
      color: #677998;
      font-size: 12px;
      margin-left: auto;
      display: block;
      border-radius: 6px;
      border: 1px solid #1b222c;
    }
    .analyse-inner-listing {
      height: 120px;
      overflow: hidden;
      transition: 0.3s height ease-in-out;
      li {
        &:nth-child(2) {
          border-bottom-width: 0;
        }
      }
      &.active {
        height: 100%;
        overflow: visible;
        transition: 0.3s height ease-in-out;
        li {
          &:nth-child(2) {
            border-bottom-width: 1px;
          }
        }
        & ~ .view-more-btn {
          display: none;
        }
        & ~ .view-less-btn {
          display: block;
        }
      }
    }
  }
  .card-header {
    p {
      font-size: 12px;
      color: #fff;
      margin-bottom: 0;
      font-weight: 600;
      img {
        max-width: 22px;
        margin-right: 10px;
      }
    }

    span {
      border: 1px solid;
      border-radius: 8px;
      padding: 4px 10px;
      font-size: 13px;
      i {
        width: 0;
        height: 0;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
      &.buy {
        color: #ff7f37;
        border-color: #ff7f37;
        i {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #ff7f37;
        }
      }
      &.sell {
        color: #1bcf83;
        border-color: #1bcf83;
        i {
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #1bcf83;
        }
      }
      &.floor {
        color: #78b6ff;
        border-color: #78b6ff;
        i {
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid#78b6ff;
        }
      }
    }
  }
}
